










import { Vue, Component, Watch } from 'vue-property-decorator';
import { ticketModule } from '@/store';

@Component
export default class System extends Vue {
  public timeMessage: string = '';

  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      ticketModule
        .fetchProduct('0000')
          .then((res: any) => {
            if (res.error && res.error.code === 999) {
              vm.timeMessage = res.error.message;
            }
            if (res.data) {
              vm.$router.go(-1);
            }
          });
    });
  }
}
